.header-top-navigation {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  border-bottom: 1px solid #ededed;
  font-size: 9px;
  color: #777;
}

.header-top-search {
  background: url(images/search.png) no-repeat;
  position: relative;
  top: 14px;
  left: -90px;
  border: none;
  outline: none;
}

.header {
  border-bottom: 3px solid #ededed;
}

.logo {
  max-height: 90px;
  margin-right: 30px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-toggle::after {
  content: none !important;
}

.dropdown-menu {
  background: black;
  border-radius: 0;
  margin: 0;
  min-width: 5rem;
  top: 7px !important;
  left: -27px !important;
}

.dropdown-menu .dropdown-item {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 9px;
  text-transform: uppercase;
  text-align: left;
}

.dropdown-item {
  padding: .25rem 2rem;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #fff;
  background-color: #000;
}

@media (max-width: 900px) {  
  .navbar-nav--mobile {
    flex-direction: row;
  }
  .logo {
    margin-right: 0;
  }
  .cartlink-mobile {
    padding-top: 15px;
  }
  .navbar-expand-sm {
    flex-flow: column;
  }
}