.toast--warning {
  background: #ffc107 !important;
}

.toast--danger {
  background: #dc3545 !important;
}

.toast--success {
  background: #28a745 !important;
}