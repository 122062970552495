.widget-image img {
  max-height: 267px;
  max-width: 370px;
  height: auto;
}

.bersi h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #333;
  margin-bottom: 25px;
  text-transform: uppercase;
  line-height: 16px;
}

.bersi p {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  color: #777;
  font-size: 13px;
  line-height: 21px;
}

.bersi p strong {
  font-weight: 700;
}

.footer-navigation--global h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #333;
  margin-bottom: 25px;
  text-transform: uppercase;
  line-height: 16px;
}

.footer-navigation--global p {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  color: #777;
  font-size: 13px;
  line-height: 21px;
}

.footer-navigation--global ul {
  padding-left: 18px;
}

.footer-navigation--global ul li {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  color: #777;
  font-size: 13px;
  line-height: 21px;
}

@media (max-width: 900px) {  
  .widget-image img {
    padding-left: 30px;
  }
}