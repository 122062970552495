.slides {
  position: relative;
  z-index: 0;
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 2;
}

.slides .slick-prev {
  left: 3%;
}

.slides .slick-next {
  right: 3%;
}