.checkout__summary-header {
  font-size: 14px;
  margin-bottom: 15px;
  font-family: "Lato";
  text-transform: uppercase;
  background: #e8e8e8;
  padding: 15px 20px;
  margin-bottom: 0;
}

.checkout__summary-content,
.checkout__summary-total,
.checkout__summary-grandtotal {
  background: #efefef;
  padding: 8px 20px;
}

.checkout__summary-total,
.checkout__summary-grandtotal {
  font-family: "Roboto Slab";
  font-size: 12px;
  color: #777;
  font-weight: 700;
  padding-bottom: 18px;
}