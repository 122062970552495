@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:600");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:300,700");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900");

.container{ max-width: 1170px; }

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/* Global desktop/large screen styling */
@media (min-width: 900px) {  
  .mobile-only {
    display: none;
  }
}

/* Mobile or smaller screen */
@media (max-width: 900px) {  
  .desktop-only {
    display: none;
  }
  .text-center--mobile-only {
    text-align: center;
  }
  .justify-center--mobile-only {
    justify-content: center;
  }
}