
.success-notification {
  background: #e6efc2;
  color: #264409;
  border: 1px solid #c6d880;
  padding: 5px 10px;
  margin: 10px 0px;
  margin-top: 0;
}

.error-notification {
  background: #fbe3e4;
  color: #ffffff;
  border: 1px solid #fddadc;
  padding: 5px 10px;
  margin: 10px 0px;
  color: #8a1f11;
  margin-top: 0;
}
