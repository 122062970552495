@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:600);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:300,700);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);
.toast--warning {
  background: #ffc107 !important;
}

.toast--danger {
  background: #dc3545 !important;
}

.toast--success {
  background: #28a745 !important;
}
.container{ max-width: 1170px; }

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/* Global desktop/large screen styling */
@media (min-width: 900px) {  
  .mobile-only {
    display: none;
  }
}

/* Mobile or smaller screen */
@media (max-width: 900px) {  
  .desktop-only {
    display: none;
  }
  .text-center--mobile-only {
    text-align: center;
  }
  .justify-center--mobile-only {
    -webkit-justify-content: center;
            justify-content: center;
  }
}
.header-top-navigation {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  border-bottom: 1px solid #ededed;
  font-size: 9px;
  color: #777;
}

.header-top-search {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAQtJREFUeNpifPv2LQMIrFmzRgJItQCxBxBLA/EdIF4GxB0hISHfGZAAE1SDK5C6ARVLAWJDIC4DYjMgPg+UV0fWxDhz5kyQDVeBOB1o4hoGNADU0Aik/IDYHCj/C2ZTOxCvx6YBCpqAGKQ4E9l5IKdNx6GBAWjYXyC1GOpXuCYhIH7NgB88AmJRZE1PgViRgCY5qEa4pk1AnIZLNTAgmKHy65E11QOxJVCyGosGTiA1B4g/Av22GB7koMgFShoA2SuA+B00QkERqwvEyUD8BYhVgDgCqHEHPHKBnAvQCF0JxDbQlKEFxK1AORMgHQcyFGi4B9wmYgBQAyiCF4FsZGIgEgBt3AS1sR0gwABZAVEmavRu3QAAAABJRU5ErkJggg==) no-repeat;
  position: relative;
  top: 14px;
  left: -90px;
  border: none;
  outline: none;
}

.header {
  border-bottom: 3px solid #ededed;
}

.logo {
  max-height: 90px;
  margin-right: 30px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-toggle::after {
  content: none !important;
}

.dropdown-menu {
  background: black;
  border-radius: 0;
  margin: 0;
  min-width: 5rem;
  top: 7px !important;
  left: -27px !important;
}

.dropdown-menu .dropdown-item {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 9px;
  text-transform: uppercase;
  text-align: left;
}

.dropdown-item {
  padding: .25rem 2rem;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #fff;
  background-color: #000;
}

@media (max-width: 900px) {  
  .navbar-nav--mobile {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .logo {
    margin-right: 0;
  }
  .cartlink-mobile {
    padding-top: 15px;
  }
  .navbar-expand-sm {
    -webkit-flex-flow: column;
            flex-flow: column;
  }
}
.widget-image img {
  max-height: 267px;
  max-width: 370px;
  height: auto;
}

.bersi h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #333;
  margin-bottom: 25px;
  text-transform: uppercase;
  line-height: 16px;
}

.bersi p {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  color: #777;
  font-size: 13px;
  line-height: 21px;
}

.bersi p strong {
  font-weight: 700;
}

.footer-navigation--global h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #333;
  margin-bottom: 25px;
  text-transform: uppercase;
  line-height: 16px;
}

.footer-navigation--global p {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  color: #777;
  font-size: 13px;
  line-height: 21px;
}

.footer-navigation--global ul {
  padding-left: 18px;
}

.footer-navigation--global ul li {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  color: #777;
  font-size: 13px;
  line-height: 21px;
}

@media (max-width: 900px) {  
  .widget-image img {
    padding-left: 30px;
  }
}
.footer-navigation {
  padding-top: 60px;
  height: 220px;
  border-top: #ededed solid 1px;
}

.footer-navigation-content-list {
  padding-left: 18px;
}

.footer-bottom {
  margin-top: 42px;
  background: #2a2a2a;
  color: #999;
  height: 70px;
}

.footer-bottom-copyright {
  padding-top: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 13px;
}

.footer-bottom-bg {
  background: #2a2a2a;
}

.footer-logo-mobile {
  padding-top: 25px;
}

@media (max-width: 900px) {  
  .footer-navigation {
    text-align: center;
  }
  .footer-navigation--global h3 {
    margin-top: 20px;
  }
  .footer-navigation--global ul {
    padding-left: 0;
  }
  .footer-navigation--global ul li {
    list-style-type: none;
  }
  .footer-navigation--global p {
    text-align: center;
  }
  .footer-bottom-copyright, 
  .input-group {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .footer-bottom-copyright {
    padding-bottom: -10px;
  }
}

@media(max-width: 575px) {
  .footer-logo-mobile {
    padding-top: 0;
  }
}

.input-autosuggest {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 300px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #dadada;
  color: black;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.checkout__summary-header {
  font-size: 14px;
  margin-bottom: 15px;
  font-family: "Lato";
  text-transform: uppercase;
  background: #e8e8e8;
  padding: 15px 20px;
  margin-bottom: 0;
}

.checkout__summary-content,
.checkout__summary-total,
.checkout__summary-grandtotal {
  background: #efefef;
  padding: 8px 20px;
}

.checkout__summary-total,
.checkout__summary-grandtotal {
  font-family: "Roboto Slab";
  font-size: 12px;
  color: #777;
  font-weight: 700;
  padding-bottom: 18px;
}
.slick-thumb {
	display: -webkit-flex !important;
	display: flex !important;
	list-style-type: none;
	padding-left: 0;
}

.slick-thumb li img {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	width: 70px;
	height: auto;
	margin-top: 10px;
	margin-right: 10px;
}

.slick-thumb li.slick-active img {
	-webkit-filter: grayscale(0);
	filter: grayscale(0);
}
.page-item.active .page-link { 
  background-color: #ccc !important; 
  border: 1px solid #dee2e6 !important; 
  z-index: 0 !important;
}

.page-link { 
  color: #757575; 
}
.slides {
  position: relative;
  z-index: 0;
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 2;
}

.slides .slick-prev {
  left: 3%;
}

.slides .slick-next {
  right: 3%;
}

.success-notification {
  background: #e6efc2;
  color: #264409;
  border: 1px solid #c6d880;
  padding: 5px 10px;
  margin: 10px 0px;
  margin-top: 0;
}

.error-notification {
  background: #fbe3e4;
  color: #ffffff;
  border: 1px solid #fddadc;
  padding: 5px 10px;
  margin: 10px 0px;
  color: #8a1f11;
  margin-top: 0;
}

