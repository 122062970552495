.slick-thumb {
	display: flex !important;
	list-style-type: none;
	padding-left: 0;
}

.slick-thumb li img {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	width: 70px;
	height: auto;
	margin-top: 10px;
	margin-right: 10px;
}

.slick-thumb li.slick-active img {
	-webkit-filter: grayscale(0);
	filter: grayscale(0);
}