.footer-navigation {
  padding-top: 60px;
  height: 220px;
  border-top: #ededed solid 1px;
}

.footer-navigation-content-list {
  padding-left: 18px;
}

.footer-bottom {
  margin-top: 42px;
  background: #2a2a2a;
  color: #999;
  height: 70px;
}

.footer-bottom-copyright {
  padding-top: 25px;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.footer-bottom-bg {
  background: #2a2a2a;
}

.footer-logo-mobile {
  padding-top: 25px;
}

@media (max-width: 900px) {  
  .footer-navigation {
    text-align: center;
  }
  .footer-navigation--global h3 {
    margin-top: 20px;
  }
  .footer-navigation--global ul {
    padding-left: 0;
  }
  .footer-navigation--global ul li {
    list-style-type: none;
  }
  .footer-navigation--global p {
    text-align: center;
  }
  .footer-bottom-copyright, 
  .input-group {
    justify-content: center;
  }
  .footer-bottom-copyright {
    padding-bottom: -10px;
  }
}

@media(max-width: 575px) {
  .footer-logo-mobile {
    padding-top: 0;
  }
}
